define("mobility-marketplace/components/mm-form-field/input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Input
    id={{@inputId}}
    @value={{@value}}
    aria-invalid={{@invalid}}
    {{on "change" @changeHandler}}
    ...attributes
  />
  */
  {
    id: "TaEUUh2S",
    block: "{\"symbols\":[\"@inputId\",\"@value\",\"@invalid\",\"&attrs\",\"@changeHandler\"],\"statements\":[[5,\"input\",[[12,\"id\",[23,1,[]]],[12,\"aria-invalid\",[23,3,[]]],[13,4],[3,\"on\",[\"change\",[23,5,[]]]]],[[\"@value\"],[[23,2,[]]]]]],\"hasEval\":false}",
    meta: {
      moduleName: "mobility-marketplace/components/mm-form-field/input.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});