define("mobility-marketplace/components/fleet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="fleet" ...attributes>
    <div class="fleet__content">
      <h2 class="font--header color--dark pb--default">
        Toyota Fleet
      </h2>
      <p class="font--body--large color--light-gray pb--large fleet__body px--default">
        Find the right Toyota vehicle for your business needs. With low cost of ownership and high resale value, you can’t go wrong with our extensive line-up of cars, crossovers, trucks and SUVs, including a range of alternative fuel vehicles.
      </p>
      <a 
        class="btn--close btn bg-color--toyota-red btn--red btn--medium btn--rounded font--subtitle color--super-light" 
        target="_blank"
        rel="noreferrer noopener"
        href="https://www.toyota.com/fleet/"
      >
        <div class="fleet-btn-content">
          Explore Fleet
          <div class="new-tab-icon">
            {{svg-jar "new-tab-icon"}}
          </div>
        </div>
      </a>
    </div>
    <div class="cta-link-container">
      {{yield}}
    </div>
  </div>
  */
  {
    id: "bWed8YxM",
    block: "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"fleet\"],[13,1],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"fleet__content\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"font--header color--dark pb--default\"],[8],[0,\"\\n      Toyota Fleet\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"font--body--large color--light-gray pb--large fleet__body px--default\"],[8],[0,\"\\n      Find the right Toyota vehicle for your business needs. With low cost of ownership and high resale value, you can\u2019t go wrong with our extensive line-up of cars, crossovers, trucks and SUVs, including a range of alternative fuel vehicles.\\n    \"],[9],[0,\"\\n    \"],[7,\"a\",true],[10,\"class\",\"btn--close btn bg-color--toyota-red btn--red btn--medium btn--rounded font--subtitle color--super-light\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noreferrer noopener\"],[10,\"href\",\"https://www.toyota.com/fleet/\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"fleet-btn-content\"],[8],[0,\"\\n        Explore Fleet\\n        \"],[7,\"div\",true],[10,\"class\",\"new-tab-icon\"],[8],[0,\"\\n          \"],[1,[28,\"svg-jar\",[\"new-tab-icon\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"cta-link-container\"],[8],[0,\"\\n    \"],[14,2],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "mobility-marketplace/components/fleet.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});