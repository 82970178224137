define("mobility-marketplace/components/app-sidebar-header", ["exports", "@loom/ember-chrome/components/app-sidebar-header"], function (_exports, _appSidebarHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _appSidebarHeader.default;
    }
  });
});