define("mobility-marketplace/templates/contact-success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B+ORQzQP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"contact-success\"],[8],[0,\"\\n  \"],[7,\"section\",true],[10,\"class\",\"success light\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"font--header color--dark\"],[8],[0,\"Thanks for connecting!\"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"font--body--large color--light-grey\"],[8],[0,\"A member of our sales team will reach out shortly.\"],[9],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"btn--close btn btn--background--dark btn--outline btn--medium btn--rounded\"]],[[\"@route\"],[\"index\"]],{\"statements\":[[0,\"\\n      Back to homepage\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"graphic\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[28,\"media\",[\"isPalm\"],null],[28,\"media\",[\"isLap\"],null]],null]],null,{\"statements\":[[0,\"          \"],[1,[28,\"svg-jar\",[\"FormSubission_Graphic_Mobile\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[28,\"svg-jar\",[\"FormSubission_Graphic\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mobility-marketplace/templates/contact-success.hbs"
    }
  });

  _exports.default = _default;
});