define("mobility-marketplace/templates/components/svg-dash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oSDFAbBO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"path\",true],[10,\"d\",\"M3,1 L7,1\"],[10,\"stroke\",\"#ffffff\"],[10,\"stroke-width\",\"1.25\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mobility-marketplace/templates/components/svg-dash.hbs"
    }
  });

  _exports.default = _default;
});