define("mobility-marketplace/modifiers/fixed-in-container", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)(function scrollingSticky(element, params, _ref) {
    var top = _ref.top;
    var sticky = false;
    var intiialScrollTop = document.scrollingElement.scrollTop;
    var topThreshold = element.parentElement.offsetTop - top;
    var elementHeight = element.getBoundingClientRect().height;
    var parentHeight = element.parentElement.getBoundingClientRect().height;
    var bottomThreshold = topThreshold + parentHeight - elementHeight;

    function setFixed() {
      if (sticky) {
        return;
      }

      element.style.position = 'fixed';
      element.style.top = "".concat(top, "px");
      element.style.bottom = '';
      element.classList.add('fixed');
      element.classList.remove('absolute');
      sticky = true;
    }

    function setAbsolute(isTop) {
      if (!sticky) {
        return;
      }

      element.style.position = 'absolute';

      if (isTop) {
        element.style.top = 0;
        element.style.bottom = '';
      } else {
        element.style.top = '';
        element.style.bottom = 0;
      }

      element.classList.add('absolute');
      element.classList.remove('fixed');
      sticky = false;
    }

    if (intiialScrollTop <= topThreshold) {
      sticky = true;
      setAbsolute(true);
    } else if (intiialScrollTop >= bottomThreshold) {
      sticky = true;
      setAbsolute(false);
    } else {
      sticky = false;
      setFixed();
    }

    function handleScroll(event) {
      var scrollingElement = event.target.scrollingElement;
      var scrollTop = scrollingElement.scrollTop;

      if (scrollTop <= topThreshold) {
        setAbsolute(true);
      } else if (scrollTop >= bottomThreshold) {
        setAbsolute(false);
      } else {
        setFixed();
      }
    }

    document.addEventListener("scroll", handleScroll);
    return function () {
      document.removeEventListener("scroll", handleScroll);
    };
  });

  _exports.default = _default;
});