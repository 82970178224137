define("mobility-marketplace/components/body-text", ["exports", "@loom/ember-text/components/body-text"], function (_exports, _bodyText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _bodyText.default;
    }
  });
});