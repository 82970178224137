define("mobility-marketplace/metrics-adapters/amplitude", ["exports", "ember-metrics/metrics-adapters/base", "ember-metrics/utils/object-transforms", "ember-metrics/utils/remove-from-dom"], function (_exports, _base, _objectTransforms, _removeFromDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var without = _objectTransforms.default.without,
      compact = _objectTransforms.default.compact,
      isPresent = _objectTransforms.default.isPresent;

  var _default = _base.default.extend({
    toStringExtension: function toStringExtension() {
      return 'Amplitude';
    },
    init: function init() {
      var config = this.config;
      var apiKey = config.apiKey,
          options = config.options;
      (false && !(apiKey) && Ember.assert("[ember-metrics] You must pass a valid `apiKey` to the ".concat(this.toString(), " adapter"), apiKey));
      /* eslint-disable */

      (function (e, t) {
        var n = e.amplitude || {
          _q: [],
          _iq: {}
        };
        var r = t.createElement("script");
        r.type = "text/javascript";
        r.integrity = "sha384-d/yhnowERvm+7eCU79T/bYjOiMmq4F11ElWYLmt0ktvYEVgqLDazh4+gW9CKMpYW";
        r.crossOrigin = "anonymous";
        r.async = true;
        r.src = "https://cdn.amplitude.com/libs/amplitude-5.2.2-min.gz.js";

        r.onload = function () {
          if (!e.amplitude.runQueuedFunctions) {
            console.log("[Amplitude] Error: could not load SDK");
          }
        };

        var i = t.getElementsByTagName("script")[0];
        i.parentNode.insertBefore(r, i);

        function s(e, t) {
          e.prototype[t] = function () {
            this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));

            return this;
          };
        }

        var o = function o() {
          this._q = [];
          return this;
        };

        var a = ["add", "append", "clearAll", "prepend", "set", "setOnce", "unset"];

        for (var u = 0; u < a.length; u++) {
          s(o, a[u]);
        }

        n.Identify = o;

        var c = function c() {
          this._q = [];
          return this;
        };

        var l = ["setProductId", "setQuantity", "setPrice", "setRevenueType", "setEventProperties"];

        for (var p = 0; p < l.length; p++) {
          s(c, l[p]);
        }

        n.Revenue = c;
        var d = ["init", "logEvent", "logRevenue", "setUserId", "setUserProperties", "setOptOut", "setVersionName", "setDomain", "setDeviceId", "setGlobalUserProperties", "identify", "clearUserProperties", "setGroup", "logRevenueV2", "regenerateDeviceId", "groupIdentify", "onInit", "logEventWithTimestamp", "logEventWithGroups", "setSessionId", "resetSessionId"];

        function v(e) {
          function t(t) {
            e[t] = function () {
              e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
            };
          }

          for (var n = 0; n < d.length; n++) {
            t(d[n]);
          }
        }

        v(n);

        n.getInstance = function (e) {
          e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();

          if (!n._iq) {
            n._iq = {};
          }

          if (!n._iq.hasOwnProperty(e)) {
            n._iq[e] = {
              _q: []
            };
            v(n._iq[e]);
          }

          return n._iq[e];
        };

        e.amplitude = n;
      })(window, document);
      /* eslint-enable */


      window.amplitude.getInstance().init(apiKey, null, options || {});
    },
    identify: function identify() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var compactedOptions = compact(options);
      var distinctId = compactedOptions.distinctId;
      var props = without(compactedOptions, 'distinctId');
      var identity = new window.amplitude.Identify();
      (false && !(distinctId) && Ember.assert("[ember-metrics] [".concat(this.toString(), "] It appears you did not pass a distictId param to \"identify\". You will need to do so in order for the session to be tagged to a specific user."), distinctId));

      if (distinctId) {
        window.amplitude.getInstance().setUserId(distinctId);
      }

      for (var k in props) {
        // eslint-disable-next-line ember/use-ember-get-and-set
        identity.set(k, props[k]);
      }

      window.amplitude.getInstance().identify(identity);
      window.amplitude.getInstance().logEvent('Identify');
    },
    trackEvent: function trackEvent() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var compactedOptions = compact(options);
      var event = compactedOptions.event;
      var props = without(compactedOptions, 'event');

      if (isPresent(props)) {
        window.amplitude.getInstance().logEvent(event, props);
      } else {
        window.amplitude.getInstance().logEvent(event);
      }
    },
    trackPage: function trackPage() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var eventOpt = {
        event: 'Page View'
      };
      var withEvent = Ember.assign(eventOpt, options);
      this.trackEvent(withEvent);
    },
    optOut: function optOut() {
      window.amplitude.getInstance().setOptOut(true);
    },
    optIn: function optIn() {
      window.amplitude.getInstance().setOptOut(false);
    },
    willDestroy: function willDestroy() {
      (0, _removeFromDom.default)('script[src*="amplitude"]');
      delete window.amplitude;
    }
  });

  _exports.default = _default;
});