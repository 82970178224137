define("mobility-marketplace/modifiers/scrolling-opacity", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)(function scrollingOpacity(element, params, _ref) {
    var _ref$fadeInStart = _ref.fadeInStart,
        fadeInStart = _ref$fadeInStart === void 0 ? 0 : _ref$fadeInStart,
        _ref$fadeInEnd = _ref.fadeInEnd,
        fadeInEnd = _ref$fadeInEnd === void 0 ? 0.25 : _ref$fadeInEnd,
        _ref$fadeOutStart = _ref.fadeOutStart,
        fadeOutStart = _ref$fadeOutStart === void 0 ? 0.75 : _ref$fadeOutStart,
        _ref$fadeOutEnd = _ref.fadeOutEnd,
        fadeOutEnd = _ref$fadeOutEnd === void 0 ? 1 : _ref$fadeOutEnd,
        activeHandler = _ref.activeHandler;
    var ticking = false;

    function setOpacity(element, opacity) {
      if (!ticking) {
        window.requestAnimationFrame(function () {
          element.style.opacity = opacity;

          if (opacity === 1) {
            activeHandler && activeHandler();
          }

          ticking = false;
        });
        ticking = true;
      }
    }

    function handleScroll() {
      var scrollIn = 1 - element.getBoundingClientRect().top / window.innerHeight;

      if (scrollIn < 0) {
        return;
      }

      if (Ember.isPresent(fadeInStart) && scrollIn < fadeInStart) {
        setOpacity(element, 0);
        return;
      }

      if (Ember.isPresent(fadeInStart) && Ember.isPresent(fadeInEnd) && scrollIn <= fadeInEnd) {
        setOpacity(element, (scrollIn - fadeInStart) / (fadeInEnd - fadeInStart));
        return;
      }

      var scrollOut = 1 - element.getBoundingClientRect().bottom / window.innerHeight;

      if (scrollOut > 1) {
        return;
      }

      if (Ember.isPresent(fadeOutEnd) && scrollOut >= fadeOutEnd) {
        setOpacity(element, 0);
        return;
      }

      if (Ember.isPresent(fadeOutEnd) && Ember.isPresent(fadeOutStart) && scrollOut >= fadeOutStart) {
        setOpacity(element, (fadeOutEnd - scrollOut) / (fadeOutEnd - fadeOutStart));
        return;
      }

      setOpacity(element, 1);
    }

    document.addEventListener("scroll", handleScroll);
    return function () {
      document.removeEventListener("scroll", handleScroll);
    };
  });

  _exports.default = _default;
});