define("mobility-marketplace/components/footer-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ul class="footer__nav">
    <li>
      <h2 class="font--subtitle color--light-gray footer__nav__header">
        {{@title}}
      </h2>
    </li> 
    {{yield (component "footer-nav/link")}}
  </ul>
  */
  {
    id: "efsStL23",
    block: "{\"symbols\":[\"@title\",\"&default\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"footer__nav\"],[8],[0,\"\\n  \"],[7,\"li\",true],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"font--subtitle color--light-gray footer__nav__header\"],[8],[0,\"\\n      \"],[1,[23,1,[]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\" \\n  \"],[14,2,[[28,\"component\",[\"footer-nav/link\"],null]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "mobility-marketplace/components/footer-nav.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});