define("mobility-marketplace/components/contact-callout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="contact-callout" ...attributes>
    <div>
      <h2 class="contact-callout__title">Ready to get started?</h2>
      <p class="contact-callout__body">Connect with us for details and pricing.</p>
    </div>
    <LinkTo
      class="contact-callout__cc-btn btn--close btn bg-color--toyota-red btn--red btn--medium btn--rounded font--subtitle"
      @route="contact-sales"
    >
      Contact
    </LinkTo>
  </div>
  */
  {
    id: "XKNPV0n6",
    block: "{\"symbols\":[\"&attrs\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"contact-callout\"],[13,1],[8],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"contact-callout__title\"],[8],[0,\"Ready to get started?\"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"contact-callout__body\"],[8],[0,\"Connect with us for details and pricing.\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"contact-callout__cc-btn btn--close btn bg-color--toyota-red btn--red btn--medium btn--rounded font--subtitle\"]],[[\"@route\"],[\"contact-sales\"]],{\"statements\":[[0,\"\\n    Contact\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "mobility-marketplace/components/contact-callout.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});