define("mobility-marketplace/components/get-connected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="get-connected" ...attributes>
    <div class="get-connected__content">
      <h2 class="font--header color--dark pb--default">
        Get Connected
      </h2>
      <p class="font--body--large color--light-gray pb--large get-connected__body px--default">
        Take the next step and connect with one of our experts to discuss products, pricing, data privacy, and more.
      </p>
      <LinkTo
        class="btn--close btn bg-color--toyota-red btn--red btn--medium btn--rounded font--subtitle color--super-light" 
        @route="contact-sales"
      >
        Get Started
      </LinkTo>
    </div>
    <div class="cta-link-container">
      {{yield}}
    </div>
  </div>
  */
  {
    id: "Owwrtigq",
    block: "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"get-connected\"],[13,1],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"get-connected__content\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"font--header color--dark pb--default\"],[8],[0,\"\\n      Get Connected\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"font--body--large color--light-gray pb--large get-connected__body px--default\"],[8],[0,\"\\n      Take the next step and connect with one of our experts to discuss products, pricing, data privacy, and more.\\n    \"],[9],[0,\"\\n    \"],[5,\"link-to\",[[12,\"class\",\"btn--close btn bg-color--toyota-red btn--red btn--medium btn--rounded font--subtitle color--super-light\"]],[[\"@route\"],[\"contact-sales\"]],{\"statements\":[[0,\"\\n      Get Started\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"cta-link-container\"],[8],[0,\"\\n    \"],[14,2],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "mobility-marketplace/components/get-connected.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});