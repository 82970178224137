define("mobility-marketplace/templates/components/svg-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gHQyq/hW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"path\",true],[10,\"d\",\"M16.032 14.618l5.332 5.332a1 1 0 1 1-1.414 1.414l-5.332-5.332a9 9 0 1 1 1.414-1.414zM9 16A7 7 0 1 0 9 2a7 7 0 0 0 0 14z\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mobility-marketplace/templates/components/svg-search.hbs"
    }
  });

  _exports.default = _default;
});