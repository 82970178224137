define("mobility-marketplace/templates/components/svg-close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kgac2+sZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"polygon\",true],[10,\"points\",\"17.82 7.36 13.18 12 17.82 16.64 16.64 17.82 12 13.18 7.36 17.82 6.18 16.64 10.82 12 6.18 7.36 7.36 6.18 12 10.82 16.64 6.18\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mobility-marketplace/templates/components/svg-close.hbs"
    }
  });

  _exports.default = _default;
});