define("mobility-marketplace/components/dropdown-menu", ["exports", "@loom/ember-dropdown/components/dropdown-menu"], function (_exports, _dropdownMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _dropdownMenu.default;
    }
  });
});