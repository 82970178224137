define("mobility-marketplace/components/dropdown-content", ["exports", "@loom/ember-dropdown/components/dropdown-content"], function (_exports, _dropdownContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _dropdownContent.default;
    }
  });
});