define("mobility-marketplace/templates/components/svg-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ysN0ycPO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"g\",true],[8],[0,\"\\n  \"],[7,\"path\",true],[10,\"d\",\"M905.3,407.7H584.1l0.6-310.3c0-0.3-0.1-0.4-0.1-0.7v-4.9h-0.5c-3.3-43.8-40.4-78.8-84.9-79.1c-44.5-0.3-81.1,34.1-83.8,77.9h-0.6l0.1,317H93.5C47.1,407.3,9.7,445.5,10,492c0.3,46.5,38.2,84.4,84.7,84.7l320.1-0.3l0.1,326.2c0.3,46.5,38.2,84.4,84.7,84.7c46.5,0.3,83.8-37.1,83.5-83.5l0.6-327.5l322.8-0.3c46.5,0.3,83.8-37.1,83.5-83.6C989.7,445.9,951.8,408,905.3,407.7z\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mobility-marketplace/templates/components/svg-add.hbs"
    }
  });

  _exports.default = _default;
});