define("mobility-marketplace/templates/components/svg-arrow-alt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0AoVXJCI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"path\",true],[10,\"d\",\"M8,7 L10.5660419,7 C10.8421843,7 11.0660419,7.22385763 11.0660419,7.5 C11.0660419,7.64098123 11.0065241,7.77541096 10.9021472,7.87018003 L6.67221664,11.7107489 C6.29093322,12.0569355 5.70907902,12.0569355 5.32779561,11.7107489 L1.09786506,7.87018003 C0.893420274,7.68455422 0.878164478,7.36833953 1.06379029,7.16389474 C1.15855935,7.05951787 1.29298908,7 1.43397032,7 L4,7 L4,1 C4,0.44771525 4.44771525,1.01453063e-16 5,0 L7,0 C7.55228475,-1.01453063e-16 8,0.44771525 8,1 L8,7 Z\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mobility-marketplace/templates/components/svg-arrow-alt.hbs"
    }
  });

  _exports.default = _default;
});