define("mobility-marketplace/templates/accessibility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HGCkpl0K",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"accessibility\"],[8],[0,\"\\n  \"],[7,\"section\",true],[10,\"class\",\"accessibility-overview__title\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"font--header color--dark\"],[8],[0,\"\\n      Accessibility\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"section\",true],[10,\"class\",\"accessibility-overview__body\"],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"font--body color--dark body\"],[8],[0,\"\\n      Toyota is committed to accessibility, diversity, and inclusion for all of its guests. We believe everyone should be able to visit Toyota.com, use our mobile apps, and access our service easily.\"],[7,\"br\",true],[8],[9],[0,\"\\n      \"],[7,\"br\",true],[8],[9],[0,\"We welcome feedback. If you notice any content, feature or functionality of the Site that you believe is not fully accessible to people with disabilities, please\\n      \"],[7,\"a\",true],[10,\"class\",\"color--toyota-red\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noreferrer noopener\"],[10,\"href\",\"https://www.toyota.com/support/#!/app/ask\"],[8],[0,\"\\n        contact us\\n      \"],[9],[0,\"\\n      and provide a description of the specific feature you feel is not fully accessible or a suggestion for improvement.\"],[7,\"br\",true],[8],[9],[0,\"\\n      \"],[7,\"br\",true],[8],[9],[0,\"We take your input seriously and will consider all feedback received as we evaluate ways to accommodate our customers and continuously improve our overall accessibility polices.\"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mobility-marketplace/templates/accessibility.hbs"
    }
  });

  _exports.default = _default;
});