define("mobility-marketplace/templates/components/svg-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mZXroWRr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"circle\",true],[10,\"class\",\"path\"],[10,\"fill\",\"none\"],[10,\"stroke-width\",\"8\"],[10,\"stroke-linecap\",\"round\"],[10,\"cx\",\"32\"],[10,\"cy\",\"32\"],[10,\"r\",\"24\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mobility-marketplace/templates/components/svg-spinner.hbs"
    }
  });

  _exports.default = _default;
});