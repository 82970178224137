define("mobility-marketplace/components/app-body", ["exports", "@loom/ember-chrome/components/app-body"], function (_exports, _appBody) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _appBody.default;
    }
  });
});