define("mobility-marketplace/templates/components/svg-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bzHToOFU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"rect\",true],[10,\"fill\",\"none\"],[10,\"stroke\",\"#fff\"],[10,\"stroke-width\",\"2\"],[10,\"x\",\"0\"],[10,\"y\",\"0\"],[10,\"width\",\"15\"],[10,\"height\",\"15\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mobility-marketplace/templates/components/svg-checkbox.hbs"
    }
  });

  _exports.default = _default;
});