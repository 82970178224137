define("mobility-marketplace/breakpoints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    palm: '(max-width: 480px)',
    lap: '(min-width: 481px) and (max-width: 1024px)',
    small: '(max-width: 720px)'
  };
  _exports.default = _default;
});