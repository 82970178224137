define("mobility-marketplace/modifiers/scrolling-sticky", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)(function scrollingSticky(element, params, _ref) {
    var offsetTop = _ref.offsetTop,
        offsetBottom = _ref.offsetBottom;
    var elementHeight = element.getBoundingClientRect().height;
    var parentHeight = element.parentElement.getBoundingClientRect().height;
    var threshold = parentHeight - elementHeight - offsetTop - offsetBottom;
    var sticky = false;

    function setSticky() {
      element.style.position = 'fixed';
      element.style.removeProperty('bottom');
      sticky = true;
    }

    function setAbsolute() {
      element.style.position = 'absolute';
      element.style.bottom = "".concat(offsetBottom, "px");
      sticky = false;
    }

    var initialScrollHeight = document.querySelector('html').scrollTop;

    if (initialScrollHeight < threshold) {
      setSticky();
    } else {
      setAbsolute();
    }

    function handleScroll(event) {
      elementHeight = element.getBoundingClientRect().height;
      parentHeight = element.parentElement.getBoundingClientRect().height;
      threshold = parentHeight - elementHeight - offsetTop - offsetBottom;
      var scrollTop = event.target.scrollingElement.scrollTop;

      if (scrollTop >= threshold) {
        if (sticky) {
          setAbsolute();
        }
      } else {
        if (!sticky) {
          setSticky();
        }
      }
    }

    document.addEventListener("scroll", handleScroll);
    return function () {
      document.removeEventListener("scroll", handleScroll);
    };
  });

  _exports.default = _default;
});