define("mobility-marketplace/components/dropdown-trigger", ["exports", "@loom/ember-dropdown/components/dropdown-trigger"], function (_exports, _dropdownTrigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _dropdownTrigger.default;
    }
  });
});