define("mobility-marketplace/templates/components/svg-checkmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bscoBo6u",
    "block": "{\"symbols\":[],\"statements\":[[7,\"polyline\",true],[10,\"fill\",\"none\"],[10,\"stroke\",\"#fff\"],[10,\"stroke-width\",\"1.25\"],[10,\"stroke-linecap\",\"round\"],[10,\"stroke-linejoin\",\"round\"],[10,\"stroke-miterlimit\",\"10\"],[10,\"points\",\"2.7,6.4 5,8.7 8.9,3.2 \"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "mobility-marketplace/templates/components/svg-checkmark.hbs"
    }
  });

  _exports.default = _default;
});